// assets
import {
  PlusSquareOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  ProfileOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  ShopOutlined,
  FolderOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  PlusSquareOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  ProfileOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  ShopOutlined,
  FolderOutlined
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const catalogue: NavItemType = {
  id: 'catalogue',
  title: 'catalogue',
  type: 'group',
  permissionKey: [
    'LIST_PRODUCTS',
    'ARCHIVE_PRODUCTS',
    'LIST_REVIEWS',
    'ARCHIVE_REVIEWS',
    'LIST_QUESTIONS',
    'ARCHIVE_QUESTIONS',
    'LIST_COMMENTS',
    'ARCHIVE_COMMENTS',
    'VIEW_OPTIONS_LIST',
    'VIEW_OPTION_ARCHIVE',
    'VIEW_ATTRIBUTES_LIST',
    'VIEW_ATTRIBUTES_ARCHIVE',
    'VIEW_ATTRIBUTE_GROUPS_LIST',
    'VIEW_ATTRIBUTE_GROUP_ARCHIVE',
    'VIEW_CATEGORY_LIST',
    'VIEW_CATEGORY_ARCHIVE',
    'VIEW_PRODUCT_LIBRARY_LIST',
    'VIEW_PRODUCT_LIBRARY_ARCHIVE',
    'VIEW_PRODUCT_REQUEST_LIST',
    'VIEW_PRODUCT_REQUEST_ARCHIVE',
    'VIEW_BRANDS_LIST',
    'VIEW_BRAND_ARCHIVE',
    'LIST_LIBRARY_BULK_UPLOAD',
    'LIST_SELLER_BULK_ASSIGNED',
    'LIST_SELLER_BULK_UPDATE'
  ],
  url: '#',
  children: [
    {
      id: 'products',
      title: 'products',
      type: 'collapse',
      url: '/apps/catalogue/products/list',
      collapsePermissionKeys: ['LIST_PRODUCTS', 'ARCHIVE_PRODUCTS'],
      permissionKey: ['LIST_PRODUCTS', 'ARCHIVE_PRODUCTS'],
      icon: icons.ShoppingCartOutlined,
      children: [
        {
          id: 'active-products',
          title: 'active-products',
          type: 'item',
          permissionKey: ['LIST_PRODUCTS', 'ARCHIVE_PRODUCTS'],
          url: '/apps/catalogue/products/active/list'
        },
        {
          id: 'pending-products',
          title: 'pending-products',
          type: 'item',
          permissionKey: ['LIST_PRODUCTS', 'ARCHIVE_PRODUCTS'],
          url: '/apps/catalogue/products/pending/list'
        },
        {
          id: 'closed-products',
          title: 'closed-products',
          type: 'item',
          permissionKey: ['LIST_PRODUCTS', 'ARCHIVE_PRODUCTS'],
          url: '/apps/catalogue/products/closed/list'
        }
      ]
    },
    {
      id: 'product-feedback',
      title: 'product-feedback',
      type: 'collapse',
      permissionKey: ['LIST_REVIEWS', 'ARCHIVE_REVIEWS', 'LIST_QUESTIONS', 'ARCHIVE_QUESTIONS', 'LIST_COMMENTS', 'ARCHIVE_COMMENTS'],
      icon: icons.StarOutlined,
      children: [
        {
          id: 'reviews',
          title: 'reviews',
          type: 'item',
          permissionKey: ['LIST_REVIEWS', 'ARCHIVE_REVIEWS'],
          url: '/apps/catalogue/product-feedback/reviews/list'
        },
        {
          id: 'questions',
          title: 'questions',
          type: 'item',
          permissionKey: ['LIST_QUESTIONS', 'ARCHIVE_QUESTIONS'],
          url: '/apps/catalogue/product-feedback/questions/list'
        },
        {
          id: 'comments',
          title: 'comments',
          type: 'item',
          permissionKey: ['LIST_COMMENTS', 'ARCHIVE_COMMENTS'],
          url: '#'
        }
      ]
    },
    {
      id: 'product-specs',
      title: 'product-specs',
      type: 'collapse',
      icon: icons.ProfileOutlined,
      permissionKey: [
        'VIEW_OPTIONS_LIST',
        'VIEW_OPTION_ARCHIVE',
        'VIEW_ATTRIBUTES_LIST',
        'VIEW_ATTRIBUTES_ARCHIVE',
        'VIEW_ATTRIBUTE_GROUPS_LIST',
        'VIEW_ATTRIBUTE_GROUP_ARCHIVE'
      ],
      children: [
        {
          id: 'options',
          title: 'options',
          type: 'item',
          permissionKey: ['VIEW_OPTIONS_LIST', 'VIEW_OPTION_ARCHIVE'],
          url: '/apps/catalogue/product-specs/options/list'
        },
        {
          id: 'attributes',
          title: 'attributes',
          type: 'item',
          permissionKey: ['VIEW_ATTRIBUTES_LIST', 'VIEW_ATTRIBUTES_ARCHIVE'],
          url: '/apps/catalogue/product-specs/attributes/list'
        },
        {
          id: 'attribute-groups',
          title: 'attribute-groups',
          type: 'item',
          permissionKey: ['VIEW_ATTRIBUTE_GROUPS_LIST', 'VIEW_ATTRIBUTE_GROUP_ARCHIVE'],
          url: '/apps/catalogue/product-specs/attribute-groups/list'
        }
      ]
    },
    {
      id: 'category',
      title: 'category',
      type: 'item',
      permissionKey: ['VIEW_CATEGORY_LIST', 'VIEW_CATEGORY_ARCHIVE'],
      url: '/apps/catalogue/category/list',
      icon: icons.AppstoreOutlined
    },
    {
      id: 'product-library',
      title: 'product-library',
      type: 'item',
      permissionKey: ['VIEW_PRODUCT_LIBRARY_LIST', 'VIEW_PRODUCT_LIBRARY_ARCHIVE'],
      url: '/apps/catalogue/product-library/list',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'product-requests',
      title: 'product-requests',
      type: 'item',
      permissionKey: ['VIEW_PRODUCT_REQUEST_LIST', 'VIEW_PRODUCT_REQUESTS_ARCHIVE'],
      url: '/apps/catalogue/product-requests/list',
      icon: icons.PlusSquareOutlined
    },
    {
      id: 'brand',
      title: 'brand',
      type: 'item',
      permissionKey: ['VIEW_BRANDS_LIST', 'VIEW_BRAND_ARCHIVE'],
      url: '/apps/catalogue/brands/list',
      icon: icons.ShopOutlined
    },
    {
      id: 'bulk-update',
      title: 'bulk-update',
      type: 'item',
      onlySeller: true,
      icon: icons.FolderOutlined,
      permissionKey: ['LIST_SELLER_BULK_UPDATE'],
      url: '/apps/catalogue/bulks/seller-bulk-update/list'
    },
    {
      id: 'bulks',
      title: 'bulks',
      type: 'collapse',
      sellerDisable: true,
      icon: icons.FolderOutlined,
      permissionKey: ['LIST_LIBRARY_BULK_UPLOAD', 'LIST_SELLER_BULK_ASSIGNED', 'LIST_SELLER_BULK_UPDATE'],
      children: [
        {
          id: 'library-bulk-upload',
          title: 'library-bulk-upload',
          type: 'item',
          permissionKey: ['LIST_LIBRARY_BULK_UPLOAD'],
          url: '/apps/catalogue/bulks/library-bulk-upload/list'
        },
        {
          id: 'seller-bulk-assigned',
          title: 'seller-bulk-assigned',
          type: 'item',
          permissionKey: ['LIST_SELLER_BULK_ASSIGNED'],
          url: '/apps/catalogue/bulks/seller-bulk-assigned'
        },
        {
          id: 'seller-bulk-update',
          title: 'seller-bulk-update',
          type: 'item',
          permissionKey: ['LIST_SELLER_BULK_UPDATE'],
          url: '/apps/catalogue/bulks/seller-bulk-update/list'
        }
      ]
    }
  ]
};

export default catalogue;
