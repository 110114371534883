import { ExportItemProps } from 'types/export';
import axiosServices, { responseSuccess, responseError } from 'utils/axios';

export function listNotifications(i18n: string, setData: (data: any) => void, data: any[], page: number) {
  return axiosServices
    .get(`notifications?clear_new=true&page=${page ?? 1}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      setData({
        data: [...data, ...res.data.item?.notifications?.data],
        hasMore: res.data?.item?.notifications?.pagination?.more,
        read: res.data.item?.new_count ?? 0
      });
    });
}

export function listNotificationRequests(
  i18n: string,
  page: number,
  offset: number,
  search: string,
  setList: (data: any) => void,
  setPagination: (data: any) => void,
  filters?: any,
  isExporting?: boolean,
  updateExportedList?: (item: ExportItemProps) => void
) {
  const endpoint = `notification-center/list-data${isExporting ? '/export' : ''}?page=${page}&offset=${offset}${
    search?.length > 0 ? `&search[value]=${search}` : ''
  }
`;

  return axiosServices
    .get(endpoint, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      if (!isExporting) {
        setPagination(res.data.items?.meta?.pagination);
        setList(res.data.items?.data);
      } else {
        const item = {
          file: res.data.items?.name,
          uuid: res.data.items?.uuid,
          endpoint: endpoint
        };
        updateExportedList?.(item);
      }
    });
}

export function getNotification(i18n: string, id: string, setData: (data: any) => void) {
  return axiosServices
    .get(`notification-center/show/${id}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      setData(res.data.item);
    });
}

export function createNotification(i18n: string, params: any) {
  return axiosServices
    .post('/notification-center/store', params, {
      headers: { 'Accept-Language': i18n }
    })
    .then(
      (res) => {
        if (res.data.code == 200) responseSuccess('notification.success.create', 'marketing');
        else responseError();

        return res.data;
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}

export function updateNotification(i18n: string, id: string, params: any) {
  return axiosServices
    .post('/notification-center/update/' + id + '', params, {
      headers: { 'Accept-Language': i18n }
    })
    .then(
      (res) => {
        if (res.data.code == 200) responseSuccess('notification.success.update', 'marketing');
        else responseError();

        return res.data;
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}
