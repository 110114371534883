import axios from 'axios';
import { responseError, responseSuccess } from 'utils/axios';

export function cacheClear(token: string, i18n: string) {
  return axios
    .get(`${process.env.REACT_APP_WEBSITE_URL}api/cache/clear`, {
      headers: { Authorization: `Bearer ${token}`, 'Accept-Language': i18n }
    })
    .then((res) => {
      if (res.data.success) responseSuccess('success.cache_clear', 'common');
      else responseError('errors.cache_clear', 'common');
    });
}
