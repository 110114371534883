import React, { MouseEvent, useEffect, createRef } from 'react';
import { Stack, Box, CircularProgress, Typography, IconButton, Tooltip, Button } from '@mui/material';
import { FilePdfOutlined, CloseOutlined, UpOutlined, DownloadOutlined, DownOutlined, RedoOutlined } from '@ant-design/icons';
import SimpleBar from 'components/third-party/SimpleBar';
import { useTranslation } from 'react-i18next';
import { useExportContext } from 'contexts/ExportContext';
import { exportList } from 'services/export';
import { ExportItemProps } from 'types/export';

const DownloadWidget = () => {
  const listRef = createRef();
  const { t } = useTranslation();
  const { updateExportedList, stopAllExportedItems, checkIfLoading, redoStoppedExportedItem, exportedList } = useExportContext();

  const [isHovering, setIsHovering] = React.useState<{ [id: string]: boolean }>({});
  const [open, setOpen] = React.useState<boolean>(true);
  const [cancel, setCancel] = React.useState<boolean>(false);
  const [widgetTitle, setWidgetTitle] = React.useState<string>(t('downloadWidget.title'));

  const widgetIsVisible = checkIfLoading() || (exportedList?.length > 0 && exportedList[exportedList.length - 1].status === 'Done');

  useEffect(() => {
    cancel ? setWidgetTitle(t('downloadWidget.canelDialog.title')) : setWidgetTitle(t('downloadWidget.title'));
  }, [cancel]);

  useEffect(() => {
    !open && setOpen(true);
    if (listRef.current) {
      const scrollContainer = listRef.current as HTMLElement;
      scrollContainer.scrollTop = 1200;
    }
  }, [exportedList.length]);

  const reExportCanceled = async (item: ExportItemProps) => {
    const { endpoint, uuid } = item;
    if (endpoint && uuid) await exportList(endpoint, uuid, redoStoppedExportedItem);
  };

  const inProgresAction = (item: any) => {
    if (isHovering[item.uuid]) {
      return actionButton({ ...item, status: 'Cancel' });
    } else {
      return <CircularProgress variant="determinate" size={30} color="secondary" value={item.progress} />;
    }
  };

  const actionButton = (item: any) => {
    const title =
      item.status === 'Cancel'
        ? t('downloadWidget.buttons.cancel')
        : item.status === 'Stopped' || item.status === 'Failed'
        ? t('downloadWidget.buttons.retry')
        : t('downloadWidget.buttons.download');
    return (
      <Tooltip title={title}>
        <IconButton
          color="primary"
          size="small"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (item.status === 'Done') {
              window.open(item.url, '_self');
            } else if (item.status === 'Stopped' || item.status === 'Failed') {
              reExportCanceled(item);
            } else {
              updateExportedList({ ...item, status: 'Stopped', stopped_uuid: item.uuid });
              exportedList.length === 1 && setOpen(false);
            }
          }}
        >
          {item.status === 'Done' ? (
            <DownloadOutlined style={{ fontSize: '20px', color: '#2D9B15' }} />
          ) : item.status === 'Cancel' ? (
            <CloseOutlined style={{ fontSize: '20px', color: '#f5222d' }} />
          ) : item.status === 'Stopped' || item.status === 'Failed' ? (
            <RedoOutlined style={{ fontSize: '20px', color: '#FF0000' }} />
          ) : null}
        </IconButton>
      </Tooltip>
    );
  };

  const cancelView = () => {
    return (
      <Stack direction="column" alignItems="center" spacing={1} my={2}>
        <Typography variant="body1">{t('downloadWidget.canelDialog.content')}</Typography>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{
              color: '#2A282F',
              fontWeight: 500
            }}
            onClick={() => {
              setCancel(false);
            }}
          >
            {t('downloadWidget.canelDialog.buttons.continueDownload')}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              bgcolor: '#FF0000',
              '&:hover': { bgcolor: '#FF0000d9' }
            }}
            size="small"
            onClick={() => {
              stopAllExportedItems();
              setCancel(false);
              setOpen(false);
            }}
          >
            {t('downloadWidget.canelDialog.buttons.cancel')}
          </Button>
        </Stack>
      </Stack>
    );
  };

  const mainView = () => {
    return (
      <SimpleBar style={{ maxHeight: 200 }} scrollableNodeProps={{ ref: listRef }}>
        {exportedList?.map((item, index) => {
          return (
            <Stack
              key={index}
              style={{ padding: 10, backgroundColor: isHovering[item.uuid] ? '#F9F9F9' : '' }}
              onMouseEnter={() => {
                setIsHovering((prev) => ({ ...prev, [item.uuid]: true }));
              }}
              onMouseLeave={() => {
                setIsHovering((prev) => ({ ...prev, [item.uuid]: false }));
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                  <FilePdfOutlined
                    style={
                      {
                        fontSize: '16px',
                        color: item.status === 'Stopped' || item.status === 'Failed' ? '#FF0000' : ''
                      } as React.CSSProperties
                    }
                  />
                  <Typography variant="body1" sx={{ color: item.status === 'Stopped' || item.status === 'Failed' ? '#FF0000' : '' }}>
                    {item.file}
                  </Typography>
                </Stack>
                {item.status === 'Loading' ? inProgresAction(item) : actionButton(item)}
              </Stack>
            </Stack>
          );
        })}
      </SimpleBar>
    );
  };

  const headerView = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={1.5}
        sx={{ bgcolor: '#F9F9F9', borderColor: 'black', borderRadius: 3 }}
      >
        <Typography variant="body1" sx={{ fontWeight: cancel ? 'regular' : 500 }}>
          {widgetTitle}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip title={open ? t('downloadWidget.buttons.hide') : t('downloadWidget.buttons.show')}>
            <IconButton
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                setOpen((prev) => !prev);
              }}
            >
              {open ? (
                <DownOutlined style={{ fontSize: '18px', color: '#545454' }} />
              ) : (
                <UpOutlined style={{ fontSize: '18px', color: '#545454' }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={t('downloadWidget.buttons.clearAll')}>
            <IconButton
              disabled={!checkIfLoading()}
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                setCancel(true);
              }}
            >
              <CloseOutlined style={{ fontSize: '20px', color: '#545454' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    );
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      width={365}
      right={100}
      bgcolor="white"
      sx={{
        display: widgetIsVisible ? 'block' : 'none',
        border: 1,
        borderColor: '#E2E2E2',
        borderTopRightRadius: 3,
        borderTopLeftRadius: 3,
        zIndex: 999
      }}
    >
      {headerView()}
      {open ? (cancel ? cancelView() : mainView()) : null}
    </Box>
  );
};

export default DownloadWidget;
