import { ExportItemProps } from 'types/export';
import axiosServices from 'utils/axios';

export async function exportList(
  endpoint: string,
  uuid: string,
  redoStoppedExportedItem: (item: ExportItemProps, new_uuid: string) => void
) {
  const res = await axiosServices.get(endpoint, {
    headers: { 'Accept-Language': 'en' }
  });
  const new_uuid = res.data.items?.uuid;
  const item = {
    file: res.data.items?.name,
    uuid: uuid,
    endpoint: endpoint,
    status: 'Loading',
    progress: '0'
  };
  redoStoppedExportedItem(item, new_uuid);
}
