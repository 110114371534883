import { WarningOutlined } from '@ant-design/icons';
import { Dialog, DialogContent, Typography, Button, Stack } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';

export const CompleteInfoDialog = () => {
  const navigate = useNavigate();
  const { showInfoComplete } = useAuth();
  const { skipCompleteInfo } = useAuth();

  return (
    <Dialog open={showInfoComplete ?? false} maxWidth="xs">
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={1.5}>
          <Avatar color={'warning'} sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <WarningOutlined />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h3" align="center" fontWeight={'700'}>
              Dear Seller
            </Typography>
            <Typography align="center" color="secondary" variant="h5" fontWeight={'400'}>
              You are required to fill in your profile info before your products can be seen on the website.
            </Typography>
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
              <Button
                fullWidth
                onClick={() => {
                  skipCompleteInfo();
                }}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color={'primary'}
                variant="contained"
                onClick={() => {
                  skipCompleteInfo();
                  navigate('/apps/profile/update');
                }}
                autoFocus
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
