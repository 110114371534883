import axios from 'axios';
import i18next from 'i18next';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

const axiosServices = axios.create({ baseURL: `${process.env.REACT_APP_ADMIN_URL || 'https://test-cms.medexsepeti.com/'}api/` });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export const responseSuccess = (title: string, ns?: string) => {
  dispatch(
    openSnackbar({
      open: true,
      message: i18next.t(title, { ns: ns ?? 'common' }),
      variant: 'alert',
      alert: { variant: 'border', color: 'success' },
      close: true
    })
  );
};
export const responseError = (title?: string, ns?: string | null) => {
  dispatch(
    openSnackbar({
      open: true,
      message: i18next.t(title ?? 'validations.something_went_wrong', { ns: ns ?? 'common' }),
      variant: 'alert',
      alert: { variant: 'border', color: 'error' },
      close: true
    })
  );
};
export default axiosServices;
