import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getFirebaseToken = async () => {
  let token = null;
  const permission = await Notification.requestPermission();
  const messagingResolve = await messaging;
  if (permission === 'granted' && messagingResolve) {
    try {
      token = await getToken(messagingResolve, { vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY });
    } catch (error) {
      console.error('Error Occured:', error);
    }
  } else if (permission === 'denied') {
    console.error('permission denied ', permission);
  } else {
    console.error('Unable to get permission to notify.');
  }
  return token;
};
